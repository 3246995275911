import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData } from "../../../framework/src/Utilities";
import toast from "react-hot-toast";
import i18nJs from "../../../components/src/TranslateLanguage";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  name: string;
  email: string;
  phoneNumber: string;
  comments: string;
  enableField: boolean;
  token: string;
  contactUsList: any;
  activeId: number;
  activeName: string;
  activeEmail: string;
  activePhoneNumber: string;
  activeDescription: string;
  activeCreatedAt: string;
  isVisible: boolean;
  goals: any;
  getContactData: any;
  getEmailNumberData: {
    email : string,
    contact_number : number,
    address:string,
  };
  openMapDialog: boolean,
  city:any,
  street:any,
  selectedLanguage: any
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class ContactusController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  contactUsApiCallId: any;
  deleteContactApiCallId: any;
  addContactApiCallId: any;
  getContactUsApiCallId: any;
  getEmailNumberApiCallId: any;
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.contactUsApiCallId = "";
    this.deleteContactApiCallId = "";
    this.addContactApiCallId = "";

    this.state = {
      name: "",
      email: "",
      phoneNumber: "",
      comments: "",
      enableField: false,
      token: "",
      contactUsList: [],
      activeId: 0,
      activeName: "",
      activeEmail: "",
      activePhoneNumber: "",
      activeDescription: "",
      activeCreatedAt: "",
      isVisible: false,
      goals: [],
      getContactData: [],
      getEmailNumberData: {
        email : "",
        contact_number : 0,
        address:"",
      },
      openMapDialog: false,
      street:'',
      city:'',
      selectedLanguage: "",
    };

    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getToken();
    if (this.isPlatformWeb() === false) {
      this.props.navigation.addListener("willFocus", () => {
        this.getToken();
      });
    }
    // Customizable Area Start
    this.getContactData();
    this.getEmailNumberData();
    const languageKey = await getStorageData("language")
    this.setState({selectedLanguage:languageKey})
    // Customizable Area End
  }

  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (this.isSessionResponseMessage(message)) {
      this.handleSessionResponseMessage(message);
    } else if (this.isRestAPIResponseMessage(message)) {
      this.handleRestAPIResponseMessage(message);
    }
    // Customizable Area End
  }

  // Customizable Area Start
  isSessionResponseMessage(message: Message): boolean {
    return getName(MessageEnum.SessionResponseMessage) === message.id;
  }
  
  handleSessionResponseMessage(message: Message) {
    const token = message.getData(getName(MessageEnum.SessionResponseToken));
    runEngine.debugLog("TOKEN", token);
    this.setState({ token });
    this.getContactUsList(token);
  }
  
  isRestAPIResponseMessage(message: Message): boolean {
    return getName(MessageEnum.RestAPIResponceMessage) === message.id;
  }
  
  handleRestAPIResponseMessage(message: Message) {
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
  
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

  
    runEngine.debugLog("API Message Received", message);
  
    if (responseJson && responseJson.data) {
      this.handleAPIResponseWithData(responseJson, apiRequestCallId);
    } else if (responseJson && responseJson.message) {
      this.handleAPIResponseWithMessage(responseJson, apiRequestCallId);
    } else if (responseJson && responseJson.errors) {
      this.handleAPIResponseWithErrors(responseJson, apiRequestCallId);
    }else{
      toast.error(i18nJs.t('Please try again later'))
    }
  }
  
  handleAPIResponseWithData(responseJson: any, apiRequestCallId: string) {
    if (apiRequestCallId === this.contactUsApiCallId) {
      this.setState({ contactUsList: responseJson.data });
    } else if (apiRequestCallId === this.addContactApiCallId) {
      this.props.navigation.goBack();
    } else if (apiRequestCallId === this.deleteContactApiCallId) {
      this.setState({ isVisible: false });
      this.getContactUsList(this.state.token);
    } else if (apiRequestCallId === this.getContactUsApiCallId) {
      this.setState({ getContactData: responseJson.data });
    } else if (apiRequestCallId === this.getEmailNumberApiCallId) {
      this.setState({ getEmailNumberData : responseJson?.data[0].attributes });
    }
  }
  
  handleAPIResponseWithMessage(responseJson: any, apiRequestCallId: string) {
    if (apiRequestCallId === this.deleteContactApiCallId) {
      this.setState({ isVisible: false });
      this.getContactUsList(this.state.token);
    }
  }
  
  handleAPIResponseWithErrors(responseJson: any, apiRequestCallId: string) {
    if (apiRequestCallId === this.addContactApiCallId) {
      responseJson.errors.forEach((error: any) => {
        if (error.contact) {
          this.showAlert(configJSON.errorTitle, error.contact.join("."));
        }
      });
    }
  }

  goalsHandle = async (value: string) =>{
    this.setState((prevState) => {
      const goals = prevState.goals;
      const index = goals.indexOf(value);
      if (index !== -1) {
        goals.splice(index, 1);
      } else {
        goals.push(value);
      }
      return {
        goals: [...goals]
      };
    });
  }
  txtNameProps = {
    onChangeText: (text: string) => {
      this.setState({ name: text });

      //@ts-ignore
      this.txtNameProps.value = text;
    },
  };

  txtEmailProps = {
    onChangeText: (text: string) => {
      this.setState({ email: text });

      //@ts-ignore
      this.txtEmailProps.value = text;
    },
  };
  txtPhoneNumberProps = {
    onChangeText: (text: string) => {
      this.setState({ phoneNumber: text });

      //@ts-ignore
      this.txtPhoneNumberProps.value = text;
    },
    // keyboardType: "phone-pad"
  };
  txtCommentsProps = {
    multiline: true,
    onChangeText: (text: string) => {
      this.setState({ comments: text });

      //@ts-ignore
      this.txtCommentsProps.value = text;
    },
  };

  setName = (text: string) => {
    this.setState({ name: text });
  };

  setEmail = (text: string) => {
    this.setState({ email: text });
  };

  setPhoneNumber = (text: string) => {
    this.setState({ phoneNumber: text });
  };

  setComments = (text: string) => {
    this.setState({ comments: text });
  };

  addQuery = () => {
    this.props.navigation.navigate("AddContactus");
  };

  hideModal = () => {
    this.setState({ isVisible: !this.state.isVisible });
  };

  setModal = (item: any) => {
    this.setState({
      activeId: item.id,
      activeName: item.attributes.name,
      activeEmail: item.attributes.email,
      activeDescription: item.attributes.description,
      activePhoneNumber: item.attributes.phone_number,
      activeCreatedAt: item.attributes.created_at,
      isVisible: !this.state.isVisible,
    });
  };

  isStringNullOrBlank(str: string) {
    return str === null || str.length === 0;
  }

  isValidEmail = (Email: string) => {
    let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (reg.test(Email) === false) {
      return false;
    } else {
      return true;
    }
  };

  addQueryApi = () => {
    if (
      this.isStringNullOrBlank(this.state.name) ||
      this.isStringNullOrBlank(this.state.email) ||
      this.isStringNullOrBlank(this.state.phoneNumber) ||
      this.isStringNullOrBlank(this.state.comments)
    ) {
      this.showAlert(
        configJSON.errorTitle,
        configJSON.errorAllFieldsAreMandatory
      );
      return false;
    } else if (!this.isValidEmail(this.state.email.trim())) {
      this.showAlert(configJSON.errorTitle, configJSON.errorEmailNotValid);
      return false;
    } else {
      let data = {
        data: {
          name: this.state.name.trim(),
          email: this.state.email.trim(),
          phone_number: this.state.phoneNumber.trim(),
          description: this.state.comments.trim(),
        },
      };

      const header = {
        "Content-Type": configJSON.contactUsApiContentType,
        token: this.state.token,
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.addContactApiCallId = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.getContactUsAPiEndPoint
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(data)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.httpPostMethod
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
    }
  };

  deleteContactUs = (id: number) => {
    const header = {
      token: this.state.token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.deleteContactApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getContactUsAPiEndPoint + `/${id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpDeleteMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  getContactUsList = (token: string) => {
    const header = {
      "Content-Type": configJSON.contactUsApiContentType,
      token: token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.contactUsApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getContactUsAPiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  btnSubmitProps = {
    onPress: () => this.addQueryApi(),
  };

  btnBackProps = {
    onPress: () => this.props.navigation.goBack(),
  };

  getContactData = async() => {
    const header = {
      token: await getStorageData("token")
    };
    const getProfileIdRequest: Message = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getContactUsApiCallId = getProfileIdRequest.messageId;
    getProfileIdRequest.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getContactData
    );
    getProfileIdRequest.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );
    getProfileIdRequest.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );

    runEngine.sendMessage(getProfileIdRequest.id, getProfileIdRequest);
  }

  getEmailNumberData = async() => {
    const header = {
      token: await getStorageData("token")
    };
    const getProfileIdRequest: Message = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getEmailNumberApiCallId = getProfileIdRequest.messageId;
    getProfileIdRequest.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getEmailNumberData
    );
    getProfileIdRequest.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );
    getProfileIdRequest.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );

    runEngine.sendMessage(getProfileIdRequest.id, getProfileIdRequest);
  }

  handleDialogMapOpen = () => {
    this.splitAdress(this.state.getEmailNumberData.address)
    this.setState({openMapDialog: true})
  }

  handleDialogMapClose = () => {
    this.setState({openMapDialog: false})
  } 

  splitAdress(inputString:string) {
    const [firstPart, secondPart] = inputString.split(',').map(part => part.trim());
    this.setState({city:firstPart})
    this.setState({street:secondPart})
  }
  // Customizable Area End
}
