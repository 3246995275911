import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { getStorageData, removeStorageData, setStorageData } from "../../../framework/src/Utilities";
export const configJSON = require("./config.js");
import React from "react";
import i18nJs from '../../../components/src/TranslateLanguage';

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  title?: string;
  classes?:any;
  // Customizable Area End
}

interface S {
  error: string;
  loading: boolean;
  token: string | null;
  dashboard: string;
  // Customizable Area Start
  profile_id: string | number;
  profile_firstname?: string;
  profile_lastname?: string;
  role: string;
  hover: Record<keyof TNavMember, boolean>;
  setting_dropdown: boolean;
  setting_dropdown11: boolean;
  menu_toggle: boolean;
  hide_menu_toggle: boolean;
  activeMenu: string;
  logoutModalVisible: boolean;
  setOpen: boolean;
  videoRef: React.RefObject<HTMLVideoElement>;
  isPlaying: boolean;
  isHover: boolean;
  tutorialData: any;
  tutorialDataId: string;
  getRoleValue: string;
  getEmailValue: any;
  tutorialRating:number;
  highlightedRating:number;
  open:boolean
  erroropen:boolean
  userRole:string;
  setLanguage: string;
  isRatingSubmitted:boolean;
  // Customizable Area End
}

interface SS {
  id: any;
}

export type TNavMember = {
  home: boolean;
  survey: boolean;
  calendar: boolean;
  users: boolean;
  chat: boolean;
  materrapedia: boolean;
  notifications: boolean;
  events: boolean;
};

export type Path = {
  route: string;
  name: keyof TNavMember;
  icon: any;
  iconHover: any;
  iconExtra: any;
  title: string;
};

export default class NavigationMenuController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  static instance: NavigationMenuController;
  getTutorialDataCallId: string = "";
  submitReviewApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    NavigationMenuController.instance = this;
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage)
    ];

    this.state = {
      profile_id: "",
      profile_firstname: "",
      profile_lastname: "",
      role: "",
      token: "",
      loading: false,
      error: "",
      dashboard: "home",
      hover: {
        home: false,
        survey: false,
        calendar: false,
        users: false,
        chat: false,
        materrapedia: false,
        notifications: false,
        events: false
      },
      setting_dropdown: false,
      setting_dropdown11: false,
      menu_toggle: false,
      hide_menu_toggle: false,
      activeMenu: "home",
      logoutModalVisible: false,
      setOpen: false,
      videoRef: React.createRef(),
      isPlaying: false,
      isHover: false,
      tutorialData: [],
      tutorialDataId:"",
      getRoleValue: '',
      getEmailValue: '',
      tutorialRating:0,
      highlightedRating:0,
      open:false,
      erroropen:false,
      userRole:"",
      setLanguage: "en",
      isRatingSubmitted: false,
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    let lng = await getStorageData("language");
    if(lng) {
      i18nJs.locale = lng;
      this.setState({setLanguage: lng});
    }
    this.getRoleValue();
    let token = await getStorageData("token")
    let collapeState = await getStorageData("collapeState")
    let guestCourse = window.location.pathname;

    !token && guestCourse !== "/GuestCourse" && guestCourse !== "/Searching" && this.props.navigation.navigate("Home")
    let getUserRole = await getStorageData('role')
    this.setState({userRole: getUserRole})
    let prev = localStorage.getItem('p')
    let now = localStorage.getItem('user_id')
    console.log(prev)
    console.log(now)
    if(now !== prev){
      localStorage.removeItem('test')
      localStorage.removeItem('searchHistory')
      localStorage.removeItem('searchAssesList')
      localStorage.removeItem('searchLiveSess')
      localStorage.removeItem('searchMyCourses')
      if(now)
      localStorage.setItem('p',now)
    }
    // Customizable Area End
  }


  // Customizable Area Start
  // Customizable Area End

  async receive(from: string, message: Message) {
    // Customizable Area Start
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    let responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if(this.getTutorialDataCallId == apiRequestCallId) {
      if(responseJson && responseJson.data){
        this.setState({ tutorialData : responseJson.data[0].attributes.videos[0].url, tutorialDataId:responseJson.data[0].id});
      }
    }

    if(this.submitReviewApiCallId == apiRequestCallId){
      if(responseJson && responseJson.data){
        this.setState({open:true, tutorialRating:0})
        this.onCall()
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start

  onCall = () => {
    setTimeout(() => {
      this.setState({open:false, erroropen:false})
    }, 5000);
  }
  getRoleValue = async () => {
    const roleValue = await getStorageData('role');
    const emailValue = await getStorageData('email');
    this.setState({ getRoleValue: roleValue , getEmailValue: emailValue });
  }
  getProfileImage = () => {
    try {
      const imagevalue = localStorage.getItem('profile_image');
      return imagevalue
    } catch (error) {
    }
  }

  handleCollape = () => {
    this.setState({ hide_menu_toggle: !this.state.hide_menu_toggle }, () => {
      setStorageData('collapeState', `${this.state.hide_menu_toggle}`)
    })
  }

  clearLocalStorage = () => {
    const user_id = localStorage.getItem('user_id')
    localStorage.setItem('p',user_id? user_id:'')
    removeStorageData('token')
    removeStorageData('role')
    removeStorageData('profile_image')
    removeStorageData('email')
    removeStorageData('user_id')
    this.props.navigation.navigate("Home");
  }

      handleClickOpen = () => {
        this.setState( { setOpen : true});
        this.getTutorialData();
        };

      handleClose = () => {
        this.setState( { setOpen : false});
        };
 
      handleVideoPlay = () => {
          this.setState({ isPlaying: true });
      };
    
      handleVideoPause = () => {
        this.setState({ isPlaying: false });
      };

      handleSkipForward = (seconds: any) => {
        this.state.videoRef.current && (this.state.videoRef.current.currentTime += seconds)
      };
    
      handleSkipBackward = (seconds: any) => {
        this.state.videoRef.current && (this.state.videoRef.current.currentTime -= seconds)
      };

      togglePlayPause = () => {
        this.setState({ isPlaying: !this.state.isPlaying });
        if (this.state.videoRef.current) {
          this.state.isPlaying
            ? this.state.videoRef.current.pause()
            : this.state.videoRef.current.play();
        }
      };

      onMouseEnter = () => {
        this.setState({ isHover : true});
      }
      onMouseLeave = () => {
        this.setState({ isHover : false});
      }

      getTutorialData = async() => {
        const header = {
          token: await getStorageData("token")
        };
        const getProfileIdRequest: Message = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getTutorialDataCallId = getProfileIdRequest.messageId;
        getProfileIdRequest.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          configJSON.getTutorialData
        );
        getProfileIdRequest.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          header
        );
        getProfileIdRequest.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          configJSON.dashboarApiMethodType
        );
    
        runEngine.sendMessage(getProfileIdRequest.id, getProfileIdRequest);
      }

      onchangeTutorialRating = (newValue:number) => {
        newValue !== null && this.setState({tutorialRating:newValue})
      }

      onClickSubmitData = () => {
        let httpData = {
          reviewable_id:  this.state.tutorialDataId,
          reviewable_type: "BxBlockTutorials2::Tutorial" ,
          description: "",
          rating : this.state.tutorialRating
        }
        if (this.state.tutorialRating !== 0) {
          this.reviewSubmit(httpData);
          this.setState({ isRatingSubmitted: true , highlightedRating: this.state.tutorialRating});
        } else {
          this.setState({ erroropen: true });
        }
        this.onCall();
     }

     reviewSubmit = async(httpData:any) => {
      const header = {
        "Content-Type": configJSON.dashboarContentType,
        token: await getStorageData("token")
      };
      const sendrequestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.submitReviewApiCallId = sendrequestMessage.messageId;
      sendrequestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.submitReviewAPIEndPoint
      );
      sendrequestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      sendrequestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpData)
      );
      sendrequestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.httpPostType
      );
      runEngine.sendMessage(sendrequestMessage.id, sendrequestMessage);
    }
  
    HandlelogoutModalVisible = (value:boolean) => {
      this.setState({ logoutModalVisible: value });
    }

    handleNavigateBrowseCourse = () => {
      if (this.state.userRole !== 'guest') {
        return "/Categoriessubcategories"
      } else {
        return "/GuestCourse"
      }
    }
  // Customizable Area End
}
